<template>
    <div id="changePwd">
      <van-sticky>
        <van-nav-bar title="忘记密码" left-arrow @click-left="onClickLeft" />
      </van-sticky>
      <div class="box p-lr16">
        <div class="one flex-wrap m-b12">
          <input type="tel" placeholder="请输入手机号" class="flex-con"  v-model="phone"  maxlength="11">
        </div>
        <div class="one flex-wrap m-b12">
        <input type="text" class="flex-con" placeholder="请输入图形验证码" v-model="pcode" maxlength="4">
          <div class="getCode cord11"  @click="need_ma1()" v-if="showTu==1">获取图形验证码</div>
          <img :src="codeImg" id='newww1'  @click="need_ma1()"  class="yzm1" v-else>
        </div>
        <div class="one flex-wrap m-b12">
          <input type="tel" placeholder="请输入验证码" class="flex-con"  v-model="sms" maxlength="6">
          <div class="getCode align-center" @click="need_ma()">{{ yzm }}</div>
        </div>
        <div class="one flex-wrap m-b12">
          <input type="password" placeholder="设置新密码" class="flex-con" v-model="pwd">
        </div>
        <div class="one flex-wrap m-b12">
          <input type="password" placeholder="再次输入密码" class="flex-con" v-model="pwd1">
        </div>
      </div>
      <div class="btn_out" @click="fnSure">
        <div class="btn align-center">
          确认
        </div>
      </div>
    </div>
  </template>
    <script>
  import { sendCode,forgetPassword } from '../api'
  export default {
    data() {
      return {
        phone:'',
            sms: '',
            yzm: '发送验证码',
            isOnclick: true,
            onClickTime: '',
            onClickTime1: '',
            isinerval: '',
            pwd:'',
            pwd1:'',
            codeImg:'',
            showTu:1,
            pcode:''
      }
    },
    mounted() {
      
    },
    methods: {
      fnSure(){
        if (this.phone == '') {
          this.$toast('请输入手机号')
          return;
        }
        if (!/^1[3456789]\d{9}$/.test(this.phone)) {
          this.$toast('请输入正确的手机号')
          return;
        }
        if (this.sms == '') {
          this.$toast('请输入验证码');
          return;
        }
        if (!/^\d{6}$/.test(this.sms)) {
          this.$toast('验证码错误');
          return;
        }
        if (this.pwd == '') {
          this.$toast('请输入密码')
          return;
        }
        if (this.pwd != this.pwd1) {
          this.$toast('两次密码不一致')
          return;
        }
        forgetPassword({
          phone: this.phone,
          code: this.sms,
          pwd:this.pwd,
          password:this.pwd1,
        }).then((data) => {
          if (data.status == 200) {
            this.$toast('修改成功')
            this.$router.go(-1);
          }
        })
      },
      onClickLeft() {
        this.$router.go(-1);
      },
      need_ma() {
        if (this.phone == '') {
          this.$toast('请输入手机号')
          return;
        }
        if (!/^1[3456789]\d{9}$/.test(this.phone)) {
          this.$toast('请输入正确的手机号')
          return;
        }
        if (this.pcode == '') {
          this.$toast('请输入图形验证码')
          return;
        }
        if (!/^\d{4}$/.test(this.pcode)) {
          this.$toast('请输入正确的图形验证码');
          return;
        }
        if (this.isOnclick) {
          //调用接口判断
          sendCode({
            phone: this.phone,
            type: 2,
            yzm:this.pcode,
          }).then((data) => {
            if (data.status == 200) {
              this.$toast('发送成功')
              if (this.isOnclick) {
                this.onClickTime = Math.floor((new Date().getTime()) / 1000)
                this.isinerval = setInterval(this.CountDown, 1000);
                this.isOnclick = false;
              }
            }
          })
        }
      },
      need_ma1(){
      if (this.phone == '') {
        this.$toast('请输入手机号')
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        this.$toast('请输入正确的手机号')
        return;
      }
      this.showTu=2;
      this.codeImg = 'https://user.deviceweixiu.com/api/Login/pictureyzm?phone=' + this.phone + '&' + Math.random();
    },
      CountDown() {
        this.onClickTime1 = 60 - (Math.floor((new Date().getTime()) / 1000) - this.onClickTime);
        if (this.onClickTime1 < 1) {
          this.yzm = '重新获取';
          clearInterval(this.isinerval);
          this.isOnclick = true;
          return;
        }
        this.yzm = this.onClickTime1 + 's';
      },
    },
  }
    </script>
    
    <style scoped>
  ::v-deep .van-nav-bar__left {
    width: 24px;
    height: 44px;
    background: url("@/assets/left_arrow.png") no-repeat center center;
    background-size: 24px 24px;
  }
  #changePwd {
    height: 100%;
    background: url("@/assets/bg.png") repeat-y center top;
    background-size: 100% 100%;
  }
  
  .box{
      padding-top: 10px;
  }
  
  .one{
      height: 40px;
      border-radius: 4px;
      background: #ffffff;
      align-items: center;
  }
  
  input[type="text"],input[type="tel"],input[type="password"] {
    border: none;
    border-radius: 4px;
    padding: 0 16px;
    color: 333;
    height: 40px;
  }
  
  input::-webkit-input-placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #aaaaaa;
  }
  .btn_out {
    height: 40px;
    padding: 18px 16px;
    box-sizing: border-box;
  }
  
  .btn {
    height: 40px;
    background: #333333;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    letter-spacing: 2px;
  }
  .getCode{
      padding: 0 15px;
      min-width: 86px;
      height: 30px;
      background: #333333;
      border-radius: 15px;
      margin-right: 12px;
      font-size: 12px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 30px;
  }
  .yzm1 {
  width: 78px;
  height: 30px;
  object-fit: cover;
  margin-right: 15px;
}
  </style>
    